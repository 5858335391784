




























































































































































import {
  SfButton, SfCheckbox, SfHeading, SfInput, SkeletonLoader
} from '~/components';
import SocialLogin from '~/modules/customer/components/SocialLogin.vue';

import {
  ref,
  computed,
  watch,
  defineComponent,
  useContext,
  useFetch,
  onMounted,
  useRouter,
  useRoute,
} from '@nuxtjs/composition-api';

import { useGuestUser, useUiState, useWishlist } from '~/composables';
import useCart from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
import { getItem, mergeItem } from '~/helpers/asyncLocalStorage';

import { customerPasswordRegExp, invalidPasswordMsg } from '~/modules/customer/helpers/passwordValidation';
import { ValidationProvider, ValidationObserver, setInteractionMode, extend } from 'vee-validate';
import { required, min, email } from 'vee-validate/dist/rules';

setInteractionMode('eager');
extend('required', {
  ...required,
  message: 'This field is required',
});
extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});
extend('email', {
  ...email,
  message: 'Invalid email',
});
extend('password', {
  message: invalidPasswordMsg,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  validate: (value) => customerPasswordRegExp.test(value),
});

export default defineComponent({
  name: 'UserAccount',
  components: {
    SkeletonLoader,
    SfHeading,
    SfInput,
    SfButton,
    SfCheckbox,
    ValidationProvider,
    ValidationObserver,
    SocialLogin
  },
  setup(_, { emit }) {
    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { $recaptcha, $config, localeRoute } = useContext();
    const isRecaptchaEnabled = ref(typeof $recaptcha !== 'undefined' && $config.isRecaptcha);
    const router = useRouter();
    const route = useRoute();

    const {
      attachToCart,
      loading: loadingGuestUser,
      error: errorGuestUser,
    } = useGuestUser();

    const { cart, load: loadCart } = useCart();
    const { loadItemsCount } = useWishlist();

    const {
      load,
      loading: loadingUser,
      register,
      login,
      user,
      isAuthenticated,
      error: errorUser,
      generateCustomerTokenBySocialProvider
    } = useUser();

    const { toggleLoginModalFromCheckoutForgotPassword } = useUiState();

    type Form = {
      firstname: string,
      lastname: string,
      email: string,
      password: string,
      is_subscribed: boolean,
      recaptchaToken?: string,
      recaptchaInstance?: string,
    };

    const userRef = ref(null);
    const isUserFormSubmitted = ref(false);
    const createUserAccount = ref(false);
    const loginUserAccount = ref(false);
    const loading = computed(() => loadingUser.value || loadingGuestUser.value);
    const form = ref<Form>({
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      is_subscribed: false,
    });
    const isFirstRun = ref(true);
    const dirtyForm = ref(userRef.value !== null ? userRef.value.flags.dirty : false);

    const canMoveForward = computed(() => !(loading.value));
    const anyError = computed(() => errorUser.value.login || errorUser.value.register || errorGuestUser.value.attachToCart);
    const isShowFormBtn = computed(() => {
      return (!isUserFormSubmitted.value && dirtyForm.value) || (isUserFormSubmitted.value && dirtyForm.value) || (!isUserFormSubmitted.value && !dirtyForm.value);
    });

    const handleFormSubmit = (reset: () => void) => async () => {
      if (isRecaptchaEnabled.value) {
        $recaptcha.init();
      }

      if (!isAuthenticated.value) {
        if (isRecaptchaEnabled.value && createUserAccount.value) {
          const recaptchaToken = await $recaptcha.getResponse();
          form.value.recaptchaToken = recaptchaToken;
          form.value.recaptchaInstance = $recaptcha;
        }
        if (createUserAccount.value) {
          await register({ user: form.value });
          if (!errorUser.value.register) {
            await login({
              user: {
                email: form.value.email,
                password: form.value.password,
              }
            });
          } else {
            await attachToCart({ email: form.value.email, cart });
          }
        } else {
          await attachToCart({ email: form.value.email, cart });
        }
      }

      if (loginUserAccount.value) {
        const recaptchaParams: { recaptchaToken?: string } = {};
        if (isRecaptchaEnabled.value) {
          recaptchaParams.recaptchaToken = await $recaptcha.getResponse();
        }

        await login({
          user: {
            email: form.value.email,
            password: form.value.password,
            ...recaptchaParams,
          },
        });
        await load();

        const formData = {
          firstname: user.value.firstname,
          lastname: user.value.lastname,
          email: user.value.email,
        };

        form.value.firstname = user.value.firstname;
        form.value.lastname = user.value.lastname;
        form.value.email = user.value.email;

        await mergeItem('checkout', { 'user-account': formData });
      }

      if (!anyError.value) {
        const formData = {
          firstname: form.value.firstname,
          lastname: form.value.lastname,
        };
        await mergeItem('checkout', { 'user-account': form.value });
        isUserFormSubmitted.value = true;
        reset();
        emit('user-form-submit', true, formData);
      }

      if (isRecaptchaEnabled.value) {
        $recaptcha.reset();
      }
    };

    watch(
      isShowFormBtn,
      (newVal) => emit('user-form-change', newVal)
    );

    watch(
      loginUserAccount,
      () => {
        isUserFormSubmitted.value = false;
        if (loginUserAccount.value === true) createUserAccount.value = false;
        emit('user-form-submit', false);
      });

    watch(
      createUserAccount,
      () => {
        if (createUserAccount.value === true) loginUserAccount.value = false;
      });

    watch(
      () => (userRef.value !== null && userRef.value.flags !== null ? userRef.value.flags.dirty : null),
      (newVal) => {
        if (!isFirstRun.value) {
          if (newVal) {
            isUserFormSubmitted.value = false;
          }
          emit('user-form-submit', isUserFormSubmitted.value);
        } else {
          isFirstRun.value = false;
        }
      }
    );

    useFetch(async () => {
      if (user.value === null) {
        await load();
      }
      if (isAuthenticated.value) {
        form.value.firstname = user.value.firstname;
        form.value.lastname = user.value.lastname;
        form.value.email = user.value.email;
      }
    });

    onMounted(async () => {
      const checkout = await getItem('checkout');
      if (checkout && checkout['user-account'] && !isAuthenticated.value) {
        const data = checkout['user-account'];
        form.value.email = data.email;
        form.value.firstname = data.firstname;
        form.value.lastname = data.lastname;
      }
      emit('user-form-change', isShowFormBtn.value);
      const routeQuery = route.value.query;

      if (routeQuery.hasOwnProperty('ref') && routeQuery.ref === 'socialLogin') {
        await generateCustomerTokenBySocialProvider({
          id: +routeQuery.id as number,
          code: decodeURIComponent(<string>routeQuery.code),
          user: routeQuery.hasOwnProperty('user') ? decodeURIComponent(<string>routeQuery.user) : '',
        });

        await router.push(localeRoute({ query: {} }));

        if (!errorUser.value) {
          await Promise.all([loadItemsCount(), loadCart(), load()]);
        }
      }
    });

    return {
      isShowFormBtn,
      canMoveForward,
      createUserAccount,
      errorUser,
      form,
      handleFormSubmit,
      isAuthenticated,
      isUserFormSubmitted,
      loading,
      loginUserAccount,
      user,
      isRecaptchaEnabled,
      userRef,
      toggleLoginModalFromCheckoutForgotPassword
    };
  },
});
