import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { SocialLoginProvider, SocialLoginProviderInput } from '~/modules/GraphQL/types';
import { useSocialLoginInterface } from '~/modules/customer/composables/useSocialLogin/useSocialLogin';

export function useSocialLogin() : useSocialLoginInterface {
  const { app } = useContext();
  const loading = ref(false);
  const error = ref(null);

  const load = async (location: SocialLoginProviderInput) : Promise<Array<SocialLoginProvider>> => {
    let results = [];

    try {
      loading.value = true;

      // @ts-ignore
      const { data } = await app.$vsf.$magento.api.loadSocialLoginProviders(location.identifier);

      // @ts-ignore
      results = data?.socialLoginProviders ?? [];
      error.value = null;
    } catch (err) {
      error.value = err;
    } finally {
      loading.value = false;
    }

    return results;
  };

  return {
    load,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useSocialLogin;
