



























import { defineComponent, PropType, ref, useContext, useFetch } from "@nuxtjs/composition-api";
import { SfButton, SfImage } from "~/components";
import { useSocialLogin } from '~/modules/customer/composables/useSocialLogin';
import { SocialLoginProvider, SocialLoginProviderInput } from "~/modules/GraphQL/types";
import { useUiNotification } from "~/composables";

export default defineComponent({
  name: 'SocialLogin',
  components: {
    SfButton,
    SfImage,
  },
  props: {
    location: {
      type: Object as PropType<SocialLoginProviderInput>,
      default: () => ({ identifier: 'register' }),
    }
  },
  setup(props) {
    const { app } = useContext();
    const { load: loadSocialLoginProviders } = useSocialLogin();
    const { send: sendNotification } = useUiNotification();
    const socialLoginProviders = ref<Array<SocialLoginProvider>>([]);

    useFetch(async () => {
      socialLoginProviders.value = await loadSocialLoginProviders(props.location);
    });

    const handleSocialLogin = (provider) => {

      if (provider.identifier === 'Apple') {
        sendNotification({
          id: Symbol('apple_warning'),
          title: app.i18n.tc('Warning for Apple Users title'),
          message: app.i18n.tc('Warning for Apple Users message'),
          type: 'danger',
          icon: 'info_shield',
          persist: true,
          action: {
            text: app.i18n.tc('Ok'),
            onClick() {
              window.location.assign(provider.redirect_url);
            }
          }
        });
      } else {
        window.location.assign(provider.redirect_url);
      }
    };

    return {
      socialLoginProviders,
      handleSocialLogin,
    }
  }
});
